import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";


import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";
import Landing from "@/views/Landing.vue";
import Upcomming from "@/views/Upcomming.vue";


const routes = [
  {
    path: "/",
    component: Upcomming,
  },
  {
    path: "/home",
    component: Landing,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).mount("#app");
